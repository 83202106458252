// src/SocialMediaLinks.js
import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const SocialMediaLinks = () => {
  return (
    <div className="flex space-x-4">
      {/* <a
        href="https://www.linkedin.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedin className="text-blue-600 text-2xl" />
      </a> */}
      <a
        href="https://www.instagram.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram className="text-pink-500 text-2xl" />
      </a>
      <a
        href="https://www.twitter.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTwitter className="text-blue-400 text-2xl" />
      </a>
      <a
        href="https://www.facebook.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook className="text-blue-800 text-2xl" />
      </a>
    </div>
  );
};

export default SocialMediaLinks;
