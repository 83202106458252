import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useCountries } from "use-react-countries";

import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Typography,
} from "@material-tailwind/react";
import phone from "../images/phone.svg";
import mail from "../images/Icon.svg";

const Contacts = () => {
  const { countries } = useCountries();
  const [country, setCountry] = React.useState("");
  const { name, flags, countryCallingCode } = countries[country || 159];
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    user_name: "",
    user_phone: "",
    user_email: "",
    option: "",
    message: "",
    agreement: "",
  });

  const [errors, setErrors] = useState({});
  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // //set country to 159 which is Nigeria on page load
  React.useLayoutEffect(() => {
    // wait for the countries to be loaded
    if (!countries) return;
    setCountry(159);
  }, [countries]);

  const sendEmail = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.user_name.trim()) {
      validationErrors.user_name = "Name is required";
    }
    if (!formData.user_email.trim()) {
      validationErrors.user_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      validationErrors.user_email = "Email is not valid";
    }
    // if (!formData.option.trim()) {
    //   validationErrors.option = "option, how we can assist you";
    // }
    if (!formData.user_phone.trim()) {
      validationErrors.user_phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.user_phone)) {
      validationErrors.user_phone =
        "Phone number is not valid make sure you input the last 10 digits ";
    }
    // if(!formData.agreement.trim()){
    //   validationErrors.agreement = "Do you agree with our terms?"
    // }
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length !== 0) return;

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            setSuccess(true);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  //use effect to hide the success message after 5 seconds
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [success]);

  return (
    <div id="contacts" className="bg-gray-100 py-20  ">
      <div className="container grid md:grid-cols-2 md:max-lg:px-3  mx-auto flex-col gap-4 ">
        <div
          color="transparent"
          shadow={false}
          className="lg:w-full lg:pr-12  mt-5 xs:max-lg:px-3 "
        >
          <Typography className="font-bold text-sm text-orange-500 ">
            Contact us
          </Typography>
          <Typography className="font-semibold text-2xl text-[#121212] mt-4 ">
            Get in Touch with us
          </Typography>
          <Typography className=" text-lg text-[#434343] my-6 ">
            Have a question, a suggestion, or just want to say hello? We'd love
            to hear from you!
          </Typography>
          <div className="flex flex-wrap gap-12 ">
            <div>
              <img src={mail} alt="" className="w-5" />
              <Typography className="mt-4">Email</Typography>
              <Typography className="  text-[#434343] text-base mt-3">
                Our friendly team is here to help
              </Typography>
              <a
                href="mailto:contact@twerce.com"
                className="text-orange-400 text-sm font-bold mt-4 "
              >
                contact@twerce.com
              </a>
            </div>
            {/* <div>
              <img src={user_phone} alt="" className="w-4" />
              <Typography className="mt-4">user_Phone</Typography>
              <Typography className="text-[#434343] text-base mt-3">
                Mon-Fri from 8am to 5pm
              </Typography>
              <a
                href="user_phone:+2348139919364"
                className="text-orange-400 text-sm font-bold mt-52 "
              >
                +1 (555) 000-0000
              </a>
            </div> */}
          </div>
        </div>

        <div className="container mx-auto lg:w-4/5 flex justify-end">
          <form
            ref={form}
            onSubmit={sendEmail}
            action="mailto:caresskakka@gmail.com"
            className="mt-8 mb-2  max-w-screen-lg  bg-white p-5 rounded-lg xs:max-lg:px-3 "
          >
            <div className="mb-1 flex flex-wrap gap-6">
              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Your Name
              </Typography>
              <input
                name="user_name"
                onChange={handleChange}
                type="text"
                size="lg"
                placeholder="Your name"
                className=" border border-gray-300 rounded-lg text-sm w-full "
              />
              {errors.user_name && (
                <span className="text-red-500 text-extrabold">
                  {errors.user_name}
                </span>
              )}
              <div className="grid md:grid-cols-2  mx-auto flex-col gap-4  items-center w-full">
                <div className="lg:w1/2">
                  <Typography variant="h6" color="blue-gray" className="">
                    Phone
                  </Typography>

                  <div className="relative flex w-full  ">
                    <Menu placement="bottom-start">
                      <MenuHandler>
                        <Button
                          ripple={false}
                          variant="text"
                          color="blue-gray"
                          className="flex h-10 items-center gap-2 rounded-r-none border border-r-1 border-blue-gray-400 bg-blue-gray-500/10 pl-3"
                        >
                          <img
                            src={flags.svg}
                            alt={name}
                            className="h-4 w-4 rounded-full object-cover"
                          />
                          {countryCallingCode}
                        </Button>
                      </MenuHandler>
                      <MenuList className="max-h-[20rem] max-w-[10rem]">
                        {countries
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map(({ name, flags, countryCallingCode }, index) => {
                            return (
                              <MenuItem
                                key={name}
                                value={name}
                                defaultValue={"Nigeria"}
                                defaultChecked={country === 153}
                                className="flex items-center gap-2"
                                onClick={() => setCountry(index)}
                              >
                                {/* <img
                                  src={flags.svg}
                                  alt={name}
                                  className="h-5 w-5 rounded-full object-cover border border-gray-300 text-sm text-gray-500"
                                /> */}
                                {name}{" "}
                                {/* <span className="">{countryCallingCode}</span> */}
                              </MenuItem>
                            );
                          })}
                      </MenuList>
                    </Menu>
                    <input
                      name="user_phone"
                      onChange={handleChange}
                      type="tel"
                      placeholder="Mobile Number"
                      className="rounded-r-md h-10 border-l-0 xs:w-full sm:w-full md:w-30 lg:w-30 border border-gray-300 text-sm text-gray-500 "
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      containerProps={{
                        className: "min-w-0",
                      }}
                    />
                  </div>
                  {errors.user_phone && (
                    <span className="text-red-500 text-extrabold">
                      {errors.user_phone}
                    </span>
                  )}
                </div>
                <div className="lg:w1/2">
                  <Typography variant="h6" color="blue-gray" className="">
                    Your Email
                  </Typography>
                  <input
                    name="user_email"
                    onChange={handleChange}
                    type="text"
                    size="lg"
                    placeholder="name@mail.com"
                    className="h-10 rounded-lg border border-gray-300 text-sm w-full "
                  />
                  {errors.user_email && (
                    <span className="text-red-500 text-extrabold">
                      {errors.user_email}
                    </span>
                  )}
                </div>
              </div>
              {/* <div className="mb-3 block form-control input-group border-none w-full">
                <Typography>How can we assist you</Typography>

                <select
                  name="option"
                  onChange={handleChange}
                  className="w-full rounded-lg border border-gray-300 text-sm text-gray-500"
                >
                  <option value="select option">Select an option</option>
                  <option value="clothing">Clothing</option>
                  <option value="electronics">Electronics</option>
                  <option value="food">Food</option>
                </select>
                {errors.option && (
                  <span className="text-red-500 text-extrabold">
                    {errors.option}
                  </span>
                )}
              </div> */}
            </div>

            <label
              htmlFor="message"
              class="block mb-2 mt-6 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your message
            </label>
            <textarea
              name="message"
              onChange={handleChange}
              rows="4"
              class="mb-3 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Leave a comment..."
            ></textarea>

            {/* <Checkbox
              label={
                <Typography
                  variant="small"
                  color="gray"
                  className="flex items-center font-normal"
                  onChange={handleChange}
                  
                >
                  I agree the
                  <a
                    href="#"
                    className="font-medium transition-colors hover:text-gray-900"
                  >
                   <u>&nbsp;Terms and Conditions</u> 
                  </a>
                </Typography>
              }
              containerProps={{ className: "pr-3"  }}
            /> */}
            <br />

            <div className="text-green-500">
              {success ? " Message sent successfully " : null}
            </div>

            <Button
              type="submit"
              className="mt-4 text-base capitalize bg-orange-500"
              fullWidth
            >
              Send message
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
