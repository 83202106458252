import React from "react";
import featuredicon from "../images/Featured icon.svg";

const Ourgoal = () => {
  return (
    <div className="bg-gray-100 py-28 xs:max-lg:px-3">
      <div className="container grid md:grid-cols-2 gap-4 mx-auto flex-col">
        <div>
          <img className="mb-6 block" src={featuredicon} alt="" />
          <h1 className="font-semibold   text-3xl text-[#121212] lg:w-4/5 ">
          Buyers
          </h1>
        </div>
        <div className="lg:w-4/5">
          <p className="mb-6 block text-[#434343] text-[18px] text-justify">
          Our goal is to connect you with a wide range of vendors, giving you multiple price options and making it easier and faster to find exactly what you need.
          </p>

          <h1 className="font-semibold text-2xl mb-4 text-[#121212] block">
            Creating a dynamic marketplace for all your needs.
          </h1>
          <p className="text-[#434343] text-[18px] text-justify">
            Our mission is to connect buyers with vendors, creating a dynamic marketplace for all your needs.
            Whether you're looking for a rare collectible, a specialized
            service, or everyday essentials, Twerce is the place to be.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Ourgoal;
