import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import iphone1 from "../images/Content.svg";
import iphone2 from "../images/Content (4).svg";
import iphone3 from "../images/Content (5).svg";

const Howitworks = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(
    () => {
      const interval = setInterval(() => {
        if (toggleState === 1) {
          setToggleState(2);
        } else if (toggleState === 2) {
          setToggleState(3);
        } else if (toggleState === 3) {
          setToggleState(1);
        }
      }, 60000); // 1 minute
      return () => clearInterval(interval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleState]
  );

  const Tab = ({ title, subTitle, tab, toggleState, isActive }) => (
    <div
      className={
        isActive
          ? "tabs cursor-default px-1 py-3 transition-all duration-300 border-[#FF6A00]  md:relative bottom-[2px]  hover:border-[#FF6A00] border-t-2  border-solid pt-4 text-center"
          : "tabs cursor-default px-1 py-3 text-center   border-white-500 pt-4"
      }
      onMouseEnter={() => toggleTab(tab)}
    >
      <h1 className="py-2 font-normal text-2xl text-[#101828]">{title}</h1>
      <Typography className=" px-4 lg:px-10 font-normal text-base text-[#434343] max-w-md">
        {subTitle}
      </Typography>
    </div>
  );

  return (
    <div id="howitworks" className="py-20">
      <div className="text-center">
        <h1 className="font-semibold mb-4 text-3xl text-[#101828] ">
          How it Works
        </h1>
        <Typography className="px-4 lg:px-10 font-normal  text-[#434343] text-lg">
          Think of it as twitter for e-commerce
        </Typography>
      </div>
      <div className="container mx-auto mt-12 flex flex-col-reverse justify-center xs:max-lg:px-3 ">
        <div className="bloc-tabs grid mt-12   md:grid-cols-3 mx-auto flex-col justify-center border-white-500 border-t-2 ">
          <Tab
            toggleState={1}
            isActive={toggleState === 1}
            tab={1}
            title={`Post your requests`}
            subTitle={`Post what you want to buy, whether in text, images or voice.`}
          />
          <Tab
            toggleState={2}
            isActive={toggleState === 2}
            tab={2}
            title={`Sellers Respond with Offers`}
            subTitle={`Within minutes, sellers Respond to your posts and give you offers.`}
          />
          <Tab
            toggleState={3}
            isActive={toggleState === 3}
            tab={3}
            title={`Choose your seller and buy`}
            subTitle={`Connect with your chosen seller, chat, negotiate, and finalize
              deals within the app.`}
          />
        </div>
        <div className="content-tabs flex justify-center items-center  ">
          <div
            className={
              toggleState === 1
                ? "content active-content border-orange-500 border-t-6 text-center"
                : "content text-center items-center  "
            }
          >
            <img className="" src={iphone1} alt="" />
          </div>
          <div
            className={
              toggleState === 2
                ? "content active-content"
                : "content items-center "
            }
          >
            <img src={iphone2} alt="" />
          </div>

          <div
            className={
              toggleState === 3
                ? "content active-content"
                : "content items-center "
            }
          >
            <img src={iphone3} alt="" />
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Howitworks;
