import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import iphone1 from "../images/buyer.svg";
import iphone2 from "../images/seller.svg";
import playstore from "../images/playstore.svg";
import appstore from "../images/appstore.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(
    () => {
      const interval = setInterval(() => {
        if (toggleState === 1) {
          setToggleState(2);
        } else {
          setToggleState(1);
        }
      }, 60000); // 1 minute
      return () => clearInterval(interval);
    },
    [toggleState]
  );

  return (
    <div className="py-13">
      <div className="container mx-auto grid md:grid-cols-2 flex-col justify-between  xs:max-lg:px-3  ">
        <div>
          <div className="lg:mt-20 flex w-max border-white-500 ">
            <HeroTab
              isActive={toggleState === 1}
              label="Vendors"
              onMouseEnter={() => toggleTab(1)}
            />
            <div className="border-b-2 w-6" />
            <HeroTab
              isActive={toggleState === 2}
              label="Buyers"
              onMouseEnter={() => toggleTab(2)}
            />
          </div>
          <div className="content-tab items-center mt-4">
            {TabContentHandler[toggleState]}
          </div>
        </div>

        <div className="content-tab flex justify-end">
          {TabContentImagehandler[toggleState]}
        </div>
      </div>
    </div>
  );
};

export default Hero;

const HeroTab = ({ onMouseEnter, label, isActive }) => (
  <div
    className={`cursor-default min-w-max py-4 text-sm border-b-2 text-[#667085] transition-all duration-200  xs:max-lg:text-xs font-medium ${
      isActive ? "border-b-2 border-[#FF6A00] text-[#FF6A00]" : ""
    }`}t
    onMouseEnter={onMouseEnter}
    style={{ fontFamily: 'Open Sans, sans-serif' }} 
  >
    {label}
  </div>
);

const HeroTabContentImage = ({ url, toggleState }) => (
  <div
    className={
      toggleState === 1 || toggleState === 2
        ? "content active-content "
        : "content "
    }
  >
    <img src={url} alt={`hero-phone-${toggleState}`} />
  </div>
);

const HeroTabContent = ({ toggleState, title, subTitle }) => (
  <div
    className={
      toggleState === 1 || toggleState === 2
        ? "content active-content "
        : "content items-center  "
    }
  >
    <h1 className="max-w-xl text-2xl text-[#101828] text-start font-semibold leading-none  lg:text-5xl lg:text-left lg:leading-tight pb-5">
      {title}
    </h1>
    <Typography
      variant="p"
      className="text-lg text-[#434343] lg:text-left lg:max-w-md pb-5"
      style={{ fontFamily: 'Open Sans, sans-serif' }} // Apply Open Sans font
    >
      {subTitle}
    </Typography>
    <div className="flex  mt-10  md:flex">
      <Link to="https://apps.apple.com/ng/app/twerce/id6477323743">
        <button className="border-none mr-4">
          <img src={appstore} alt="" />
        </button>
      </Link>
      <Link to="https://play.google.com/store/apps/details?id=com.twerceapp.twerce">
        <button>
          <img src={playstore} alt="" />
        </button>
      </Link>
    </div>
  </div>
);

const TabContentHandler = {
  1: (
    <HeroTabContent
      toggleState={2}
      title={`Get Instant Alerts on What Customers Are Looking For.`}
      subTitle={`Stay ahead of the competition by knowing exactly what customers need as soon as they express it. With Twerce, you receive real-time alerts whenever a customer posts a request for a product or service you offer. No more guessing or waiting – be the first to know and seize the opportunity to connect with potential clients.`}
    />
    
  ),
  2: (
    <HeroTabContent
      toggleState={1}
      title={"Post What You Need & Let Vendors Come to You!"}
      subTitle={`No need to scour the internet or social media for vendors and professionals. Simply post your request on Twerce, and vendors will be notified, reaching out to you with competitive prices and offers.`}
    />
  ),
};

const TabContentImagehandler = {
  1: <HeroTabContentImage url={iphone2} toggleState={1} />,
  2: <HeroTabContentImage url={iphone1} toggleState={2} />,
};
