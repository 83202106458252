import React from "react";
import { Slide, Fade } from "react-slideshow-image";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";

import "react-slideshow-image/dist/styles.css";

const spanStyle = {
  // padding: "20px",
  color: "white",
  background: "",
  opacity: "100",
};

const divStyle = {
  display: "flex",
  alignItems: "end",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "600px",
  borderTop: "2px white solid",
};
const slideImages = [
  {
    url: require("../images/Content.png"),
    caption: "Twerce really helped us find what we were looking for quickly",
    fullname: "Emma Jones",
    location: "Akure",
    stars: "⭐⭐⭐⭐⭐",
  },
  {
    url: require("../images/Content.png"),
    caption:
      "Twerce really makes for comfortable trading. I've experienced it firsthand",
    fullname: "Rhema Chuks",
    location: "Lagos",
    stars: "⭐⭐⭐⭐⭐",
  },
  {
    url: require("../images/Content.png"),
    caption: "I make weekly profit from reselling goods thanks to Twerce",
    fullname: "Isabella Garos",
    location: "Lagos",
    stars: "⭐⭐⭐⭐⭐",
  },
];

const buttonStyle = {};

const properties = {
  prevArrow: (
    <button className="bg-white mr-16 xs:max-sm:ml-[63%] sm:max-md:ml-[82%] md:max-lg:ml-[70%] mt-[30rem] lg:max-xl:ml-[77%] xl:max-2xl:ml-[82%] 2xl:max-3xl:ml-[85%] 3xl:ml-[90%]  rounded-full">
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_579_9255)">
          <rect width="44" height="44" rx="22" fill="white" />
          <path
            d="M29 22H15M15 22L22 29M15 22L22 15"
            stroke="#FF6A00"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            x="0.5"
            y="0.5"
            width="43"
            height="43"
            rx="21.5"
            stroke="white"
            stroke-opacity="0.5"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_579_9255"
            x="-8"
            y="-8"
            width="60"
            height="60"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_579_9255"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_579_9255"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </button>
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }} className=" mt-[30rem] mr-4">
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_579_9256)">
          <rect width="44" height="44" rx="22" fill="white" />
          <path
            d="M15 22H29M29 22L22 15M29 22L22 29"
            stroke="#FF6A00"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            x="0.5"
            y="0.5"
            width="43"
            height="43"
            rx="21.5"
            stroke="white"
            stroke-opacity="0.5"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_579_9256"
            x="-8"
            y="-8"
            width="60"
            height="60"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_579_9256"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_579_9256"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </button>
  ),
};

const CarouselItem1 = () => {
  return (
    <div className="container mx-auto ">
      <Slide {...properties}>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div
              style={{ ...divStyle, backgroundImage: `url(${slideImage.url})` }}
            >
              <span
                style={spanStyle}
                className="w-full backdrop-blur-lg border-t bg-gradient-to-r from-black xs:max-lg:p-4 lg:max-xl:p-5 xl:max-2xl:p-5 2xl:max-3xl:p-10 3xl:p-6 "
              >
                <p className="font-semibold text-xl xs:max-sm:text-sm  sm:max-md:text-md lg:max-xl:text-xl xl:max-2xl:text-2xl mb-4 block ">
                  <i className="">"</i>
                  {slideImage.caption}
                  <i>"</i>
                </p>
                <p className="font-semibold mb-2 block  text-2xl xs:max-lg:text-2xl ">
                  {slideImage.fullname}
                </p>
                <p className="font-extrabold text-xs ">{slideImage.location}</p>
                <p className="font-extrabold text-xs ">{slideImage.stars}</p>
              </span>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default CarouselItem1;
