import React from "react";
import ladyholdsphone from "../images/Content (1).svg";
import { Link } from "react-router-dom";
import playstore from "../images/playstore.svg";
import appstore from "../images/appstore.svg";

const content = [
  {
    title: "Immediate Notifications",
    subTitle:
      "As soon as a customer posts a requirement, you get an instant alert, allowing you to respond quickly and effectively.",
  },
  {
    title: "Targeted Leads",
    subTitle:
      "Receive notifications tailored to your business, ensuring that every alert is relevant and valuable to you.",
  },
  {
    title: "Increased Visibility",
    subTitle:
      "By responding promptly to customer needs, you position your business as responsive and reliable, enhancing your reputation and visibility",
  },
  {
    title: "Boost Your Sales",
    subTitle:
      "Convert more leads into customers by being proactive. With Twerce, you’re always in the loop, ready to offer the perfect solution",
  },

];
const About = () => {
  return (
    <div id="about" className="container mx-auto mt-5 xs:max-lg:px-3 ">
      <div className="">
        <h1 className="font-medium text-justify text-3xl lg:text-4xl text-[#101828] ">
          Why Your Business Need Twerce
        </h1>
        {/* <p className="text-lg mt-4 font-normal text-[#434343] ">
          Connecting Buyers to Sellers and Service Providers Like Never Before
        </p> */}
      </div>
      <div className=" mt-10 grid md:grid-cols-2 gap-4 mx-auto flex-col">
        <div className="">
          {content.map(({ title, subTitle }, index) => (
            <div key={index} className="lg:mt-0 my-6 text-[#434343]  ">
              <h1 className="text-xl mb-2 block text-[#101828] font-medium">
                {title}
              </h1>
              <p className="font-normal lg:w-9/12 text-base">{subTitle}</p>
            </div>
          ))}

          <div className="flex  mt-16  md:flex">
            <Link to="" className=" ">
              <button className="border-none mr-4">
                <img src={appstore} alt="" />
              </button>
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.twerceapp.twerce">
              <button className="">
                <img src={playstore} alt="" />
              </button>
            </Link>
          </div>
        </div>

        <div className="flex justify-end">
          <img src={ladyholdsphone} alt="seller" />
        </div>
      </div>
    </div>
  );
};

export default About;
